<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Población (Año de última actualización)
      </span>
    </v-card-title>
    <v-card-text>
      <v-row v-if="loading">
        <v-col
          cols="12"
          sm="12"
          md="6"
        >
          <v-progress-linear :indeterminate="true"></v-progress-linear>

          <v-sheet
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="article"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
        >
          <v-sheet
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="article"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ error }}
      </v-alert>

      <v-row v-if="!loading && !error">
        <v-col
          cols="12"
          sm="12"
          md="6"
        >
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Población total</td>
                  <td>{{ pdata.PoblacionTotal.Total | numberFormat }} ({{ pdata.PoblacionTotal.Anio}})</td>
                </tr>
                <tr>
                  <td>Población masculina</td>
                  <td>{{ pdata.Hombres.Total | numberFormat }} ({{ pdata.Hombres.Anio}})</td>
                </tr>
                <tr>
                  <td>Población femenina</td>
                  <td>{{ pdata.Mujeres.Total | numberFormat }} ({{ pdata.Mujeres.Anio}})</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
        >
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Población con seguro social</td>
                  <td>{{ pdata.SeguroSocial.Total | percentFormat }}% ({{ pdata.SeguroSocial.Anio}})</td>
                </tr>
                <tr>
                  <td>Población económicamente activa</td>
                  <td>{{ pdata.Activa.Total | percentFormat }}% ({{ pdata.Activa.Anio}})</td>
                </tr>
                <tr>
                  <td>Empleo mujeres en lo turístico e industrial (Jalisco)</td>
                  <td>{{ pdata.EmpleoMujeres.Total | numberFormat }} ({{ pdata.EmpleoMujeres.Anio}})</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div>
        <p class="text-right mt-3 font-weight-bold">
          Fuente información: API INEGI
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import poblacion from '@/services/poblacion'

export default ({
  name: 'TpPoblacion',
  data() {
    return {
      pdata: {},
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      poblacion.getPoblacion().then(resp => {
        this.pdata = resp.data
        this.loading = false
      }).catch(err => {
        this.error = err.response.data
        this.loading = false
      })
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  setup() {
  },
})
</script>
