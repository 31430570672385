<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Educación (Año de última actualización)</span>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>

        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        {{ errMessage }}
      </v-alert>

      <v-simple-table>
        <template
          v-if="!loading && !error"
          v-slot:default
        >
          <tbody>
            <tr>
              <td>Porcentaje de población (de más de 15 años) que es analfabeta</td>
              <td>{{ data.Analfabeta.Total | percentFormat }}% ({{ data.Analfabeta.Anio}})</td>
            </tr>
            <tr>
              <td>Grado promedio de escolaridad (en la población de más de 15 años)</td>
              <td>{{ data.Escolaridad.Total | percentFormat }}%  ({{ data.Escolaridad.Anio}})</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <div>
        <p class="text-right mt-3 font-weight-bold">
          Fuente información: API INEGI
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import poblacion from '@/services/poblacion'

export default ({
  name: 'TpEducacion',
  data() {
    return {
      data: {},
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.error = false
      poblacion.getEducacion().then(resp => {
        this.data = resp.data
        if (this.data.error) {
          this.error = true
          this.errMessage = this.data.error
        }
        this.loading = false
      }).catch(err => {
        this.error = err.response.data
        this.loading = false
      })
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  setup() {
  },
})
</script>
