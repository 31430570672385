<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Economía (Año de última actualización)</span>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>

        <v-skeleton-loader
          class="mx-auto"
          type="article,article,article"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        {{ errMessage }}
      </v-alert>

      <v-simple-table>
        <template
          v-if="!loading && !error"
          v-slot:default
        >
          <tbody>
            <tr>
              <td>Ventas Internacionales Principal Producto Exportado</td>
              <td>{{ data.VentasInt.Total | numberFormat }} USD ({{ data.VentasInt.Anio}})</td>
            </tr>
            <tr>
              <td>Ventas Internacionales Principal Destino</td>
              <td>{{ data.VentasIntDestinoP.Total | numberFormat }} USD ({{ data.VentasIntDestinoP.Anio}})</td>
            </tr>
            <tr>
              <td>Compras Internacionales Principal Producto Importado</td>
              <td>{{ data.ComprasInt.Total | numberFormat }} USD ({{ data.ComprasInt.Anio}})</td>
            </tr>
            <tr>
              <td>Compras Internacionales Principal Origen</td>
              <td>{{ data.ComprasIntOrigen.Total | numberFormat }} USD ({{ data.ComprasIntOrigen.Anio}})</td>
            </tr>
            <tr>
              <td>Comercio Internacional Neto Compras Internacionales</td>
              <td>{{ data.NetoComprasInt.Total | numberFormat }} USD ({{ data.NetoComprasInt.Anio}})</td>
            </tr>
            <tr>
              <td>Comercio Internacional Neto Ventas Internacionales</td>
              <td>{{ data.NetoVentasInt.Total | numberFormat }} USD ({{ data.NetoVentasInt.Anio}})</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <div>
        <p class="text-right mt-3 font-weight-bold">
          Fuente información: API datamexico
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import poblacion from '@/services/poblacion'

export default ({
  data() {
    return {
      data: {},
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.error = false
      poblacion.getEconomia().then(resp => {
        this.data = resp.data
        if (this.data.error) {
          this.error = true
          this.errMessage = this.data.error
        }
        this.loading = false
      }).catch(err => {
        this.error = err.response.data
        this.loading = false
      })
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  setup() {
  },
})
</script>
