<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <tp-poblacion></tp-poblacion>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <tp-vivienda></tp-vivienda>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <tp-economia></tp-economia>
      <tp-educacion class="mt-1"></tp-educacion>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <tp-nacimientos></tp-nacimientos>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <tp-proyeccion-turismo></tp-proyeccion-turismo>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <tp-proyeccion-crecimiento></tp-proyeccion-crecimiento>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <tp-proyeccion-pib></tp-proyeccion-pib>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <tp-camaras></tp-camaras>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <tp-comercios></tp-comercios>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TpEconomia from './TpEconomia'
import TpEducacion from './TpEducacion'
import TpNacimientos from './TpNacimientos'
import TpPoblacion from './TpPoblacion'
import TpProyeccionCrecimiento from './TpProyeccionCrecimiento'
import TpProyeccionPib from './TpProyeccionPib'
import TpProyeccionTurismo from './TpProyeccionTurismo'
import TpVivienda from './TpVivienda'
import TpCamaras from './TpCamaras'
import TpComercios from './TpComercios'

export default defineComponent({
  name: 'PoblacionTequila',
  components: {
    TpPoblacion,
    TpEconomia,
    TpEducacion,
    TpVivienda,
    TpNacimientos,
    TpProyeccionTurismo,
    TpProyeccionCrecimiento,
    TpProyeccionPib,
    TpCamaras,
    TpComercios,
  },
  computed: {
  },
  mounted() {
  },
  setup() {
  },
})
</script>
