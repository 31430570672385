<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Vivienda y Calidad de Vida (Año de última actualización)</span>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>

        <v-skeleton-loader
          class="mx-auto"
          type="article,article,article,article"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        {{ errMessage }}
      </v-alert>

      <v-simple-table>
        <template
          v-if="!loading && !error"
          v-slot:default
        >
          <tbody>
            <tr>
              <td>Número de viviendas en Tequila</td>
              <td>{{ vdata.TotalViviendas.Total | numberFormat }} ({{ vdata.TotalViviendas.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas con electricidad</td>
              <td>{{ vdata.Electricidad.Total | percentFormat }}% ({{ vdata.Electricidad.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas con agua entubada</td>
              <td>{{ vdata.Agua.Total | percentFormat }}% ({{ vdata.Agua.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas con baño</td>
              <td>{{ vdata.Banio.Total | percentFormat }}% ({{ vdata.Banio.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas con televisión</td>
              <td>{{ vdata.Tv.Total | percentFormat }}% ({{ vdata.Tv.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas con computadora personal</td>
              <td>{{ vdata.Computadora.Total | percentFormat }}% ({{ vdata.Computadora.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas con teléfono fijo</td>
              <td>{{ vdata.Telefono.Total | percentFormat }}% ({{ vdata.Telefono.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas con teléfono celular</td>
              <td>{{ vdata.Celular.Total | percentFormat }}% ({{ vdata.Celular.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas con internet</td>
              <td>{{ vdata.Internet.Total | percentFormat }}% ({{ vdata.Internet.Anio}})</td>
            </tr>
            <tr>
              <td>Porcentaje de viviendas televisión de paga </td>
              <td>{{ vdata.TvPaga.Total | percentFormat }}% ({{ vdata.TvPaga.Anio}})</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <div class="mt-3">
        <p class="text-right font-weight-bold">
          Fuente información: API INEGI
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import poblacion from '@/services/poblacion'

export default ({
  name: 'TpVivienda',
  data() {
    return {
      vdata: {},
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.error = false
      poblacion.getViviendas().then(resp => {
        this.vdata = resp.data
        if (this.vdata.error) {
          this.error = true
          this.errMessage = this.vdata.error
        }

        this.loading = false
      }).catch(err => {
        if (err.response.status === 401) {
          //   this.$router.push('/pages/login')
        } else {
          this.error = err.response.data
          this.loading = false
        }
      })
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  setup() {
  },
})
</script>
