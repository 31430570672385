import axios from 'axios'
import environment from '@/environments/environment'

const path = environment.apiUrl

export default {
  getPoblacion() {
    return axios.get(`${path}/poblacion`, {
    })
  },
  getViviendas() {
    return axios.get(`${path}/vivienda`, {
    })
  },
  getEducacion() {
    return axios.get(`${path}/educacion`, {
    })
  },
  getEconomia() {
    return axios.get(`${path}/economia`, {
    })
  },
  getNacimientos() {
    return axios.get(`${path}/nacimientos_tequila`, {
    })
  },
  getProyeccionesTurismo() {
    return axios.get(`${path}/proyecciones_turismo`, {
    })
  },
  getProyeccionResidentes() {
    return axios.get(`${path}/proyecciones_residentes`, {
    })
  },
  getProyeccionPib() {
    return axios.get(`${path}/proyecciones_pib`, {
    })
  },
  getUbicacionCamaras() {
    return axios.get(`${path}/camaras`, {
    })
  },
  getEstablecimientos() {
    return axios.get(`${path}/establecimientos`, {
    })
  },
}
